export default function GetBranchId() {
    let user_info = JSON.parse(localStorage.getItem('user_info'))
    if (user_info) {
        let branch_id = user_info.branch_id
        if(branch_id != 1) {
            return branch_id
        }
        else {
            return null
        }
    }
    else {
        return null
    }
}